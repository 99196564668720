<section fxLayout="row" fxLayoutGap="32px">
  <div fxFlex>

    <div fxLayout="column" fxLayoutGap="32px" class="mat-padding">
      <div>
        <div fxLayout="row wrap" fxLayoutGap="4px">
          <div>
            Supports:
          </div>
          <div *ngFor="let support of data; let i = index">
            <div class="pointer" [ngClass]="{'active': selectedSupport === i}" (click)="selectedSupport = i">
              {{i + 1}}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="16px">
        <button *ngIf="data.length" type="button" mat-raised-button color="warn" (click)="removeSupport()">
          Remove support
        </button>

        <button *ngIf="selectedSupport !== 0 && (data.length !== 0)" type="button" mat-raised-button color="primary" (click)="saveData(); selectedSupport = selectedSupport - 1">
          Previous support
        </button>

        <button *ngIf="selectedSupport !== (data.length - 1) && data.length" type="button" mat-raised-button color="primary" (click)="saveData(); selectedSupport = selectedSupport + 1">
          Next support
        </button>

        <button type="button" mat-raised-button color="primary" (click)="addSupport(); selectedSupport = selectedSupport + 1">
          Add support
        </button>
      </div>

      <ng-container *ngFor="let support of data; let i = index">
        <polylearn-support *ngIf="i === selectedSupport"
         [support]="support"
         [isValid]="isValid"
         (onRemoveQuestion)="removeQuestion($event)"
         (onAddQuestion)="addQuestion()"
         (onAddChoice)="addChoice($event)"
         (onRemoveChoice)="removeChoice($event)"
         [index]="i">

        </polylearn-support>
      </ng-container>

    </div>

  </div>

  <div fxFlex="30%">
    <polylearn-json-result (onUpdate)="reloadData($event);" [data]="data">

    </polylearn-json-result>
  </div>
</section>
