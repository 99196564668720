import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Support } from '../../app.component';
import { FormBuilder, FormGroup, NgForm, ControlContainer } from '@angular/forms';

import * as Quill from 'quill';

declare var sanitizeHtml;


const TEMPLATES = {
  'email': `
  <div class="email-t">
        <div class="gmailbasics">
  <table class="titlebar">
    <tbody><tr><td><span>Subject</span>: <span>content</span></td>
  </tr></tbody></table>
  <table class="emailinfo">
     <tbody><tr>
      <td class="addressnsubject"><span>From</span>: content</td>
    </tr>
    <tr>
      <td class="addressnsubject"><span>To</span>: <span>Barry</span> <span>Warr</span></td>
    </tr>

  </tbody></table>
<div class="body"><div class="bodytext">
Body
  </div>
</div>
    </div></div>
  `,
  'article': `
  <div class="fieldset long-text">
  Content
  </div>
  `,
  'fieldset': `
  <div class="fieldset">
  Content
  </div>
  `,
}

@Component({
  selector: 'polylearn-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SupportComponent implements OnInit {
  templates = TEMPLATES;

  @Input() support: Support;
  @Input() index: number;
  @Input() isValid: boolean;

  @Output() onAddQuestion = new EventEmitter<any>();

  @Output() onAddChoice = new EventEmitter<number>();
  @Output() onRemoveChoice = new EventEmitter<{questionIndex: number, choiceIndex: number}>();
  @Output() onRemoveQuestion = new EventEmitter<number>();

  supportForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    /*
    let editor = new Quill('.editor', {
      theme: 'snow'
    });  // First matching element will be used
    */

  }

  public cleanHTML (plugin, args) {
    console.log(args.content);
    // args.content += ' preprocess';

    args.content = sanitizeHtml(args.content, {
      allowedTags: ['p', 'b', 'i', 'em', 'strong' ],
      /*
      allowedAttributes: {
        'a': [ 'href' ]
      }
      */
    });

  }

}
