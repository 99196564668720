<div fxLayout="column" fxLayoutGap="64px">
  <polylearn-question *ngFor="let question of questions; let i = index"
   [question]="question"
   (onRemoveQuestion)="onRemoveQuestion.emit(i)"
   (onRemoveChoice)="onRemoveChoice.emit({questionIndex: i, choiceIndex: $event})"
   (onAddChoice)="onAddChoice.emit(i)"
   [index]="i">

  </polylearn-question>
</div>
