<div fxLayout="row">
  <form fxFlex="100%" class="mat-elevation-z2 mat-padding support"
    [ngClass]="{isValid: isValid}"
    novalidate
    supportForm="ngForm">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex>
        <h1 class="mat-headline">Support n° {{index + 1}}</h1>
      </div>
      <div>
          <mat-form-field class="example-full-width">
            <input required min="1" max="15" matInput name="part" type="number" placeholder="Part: " [(ngModel)]="support.part">
          </mat-form-field>
      </div>
    </div>

    <mat-form-field class="example-full-width">
      <textarea [(ngModel)]="support.notes" name="supportNotes" matInput placeholder="Notes" rows="5">
      </textarea>
    </mat-form-field>

    <div fxLayout="row">
      <div fxFlex="66%">
          <mat-form-field class="example-full-width">
            <input matInput name="training_audio" type="url" placeholder="Training_audio" [(ngModel)]="support.training_audio">
          </mat-form-field>

        </div>

        <div fxFlex="33%">
          <audio
          controls
          [src]="support.training_audio">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="66%">
          <mat-form-field class="example-full-width">
            <input matInput name="exam_audio" type="url" placeholder="exam_audio" [(ngModel)]="support.exam_audio">
          </mat-form-field>

        </div>

        <div fxFlex="33%">
          <audio
          controls
          [src]="support.exam_audio">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </div>
    </div>


    <div fxLayout="row">
      <div fxFlex="66%">
          <mat-form-field class="example-full-width">
            <input matInput name="image" type="url" placeholder="Image" [(ngModel)]="support.image">
          </mat-form-field>

        </div>

        <div fxFlex="33%">
          <img style="width: 100%; height: auto;"
          [src]="support.image">
      </div>
    </div>



    <editor [init]="{
      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample',
      quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
      height: 600,
      paste_enable_default_filters: false,
      paste_preprocess: cleanHTML,
      content_css : '/assets/editor.css',
      templates: [
        {title: 'Email', description: 'Email', content: templates['email']},
        {title: 'Article', description: 'Article', content: templates['article']},
        {title: 'Fieldset', description: 'Fieldset', content: templates['fieldset']}
      ]
    }"
    apiKey="snod8dnubkfw3k1c2t1yg26pyqv25tehdqs25a3vdi52y4hx" [(ngModel)]="support.text" name="supportText" placeholder="Support text" rows="5">
    </editor>


    <mat-form-field *ngIf="false" class="example-full-width">

      <textarea [(ngModel)]="support.text" name="supportText" matInput placeholder="Support text" rows="5">
      </textarea>
    </mat-form-field>

    <polylearn-questions
     [questions]="support.questions"
     (onRemoveChoice)="onRemoveChoice.emit($event)"
     (onAddChoice)="onAddChoice.emit($event)"
     (onRemoveQuestion)="onRemoveQuestion.emit($event)"
    >
    </polylearn-questions>

    <br>
    <br>
    <button type="button" mat-raised-button color="primary" (click)="onAddQuestion.emit()">
      Add question
    </button>
  </form>
</div>

