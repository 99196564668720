<button type="button" mat-raised-button color="primary" (click)="onRemoveQuestion.emit()">
  Remove question
</button>

<div>
  <mat-form-field class="example-full-width">
    <textarea matInput [name]="'text' + '_' + uniq " [(ngModel)]="question.text" [placeholder]="'Question text ' + (index + 1)" rows="5">
    </textarea>
  </mat-form-field>
  <br>
  <br>
</div>

<div>
  <div fxLayout="column" fxLayoutGap="32px">
    <div *ngFor="let choice of question.choices; let indexChoice = index" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex>
        <mat-form-field class="example-full-width">
          <input matInput [placeholder]="'Choice ' + (indexChoice + 1)" [name]="'choice_' + uniq + '_' + (indexChoice + 1)" [(ngModel)]="choice.text">
        </mat-form-field>

        <mat-checkbox class="is-correct" [name]="'iscorrect_' + uniq + '_' + (indexChoice + 1)" [(ngModel)]="choice.is_correct">
          is_correct
        </mat-checkbox>
      </div>

      <div>
        <button type="button" (click)="onRemoveChoice.emit(indexChoice)" mat-icon-button color="warn">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="8px">
      <button type="button" fxFlex (click)="onAddChoice.emit()" mat-stroked-button color="primary">
        Add choice
      </button>
      <button type="button" fxFlex (click)="onAddChoice.emit(); onAddChoice.emit();"  mat-stroked-button color="primary">
        Add 2 choices
      </button>
      <button type="button" fxFlex (click)="onAddChoice.emit(); onAddChoice.emit(); onAddChoice.emit();" mat-stroked-button color="primary">
        Add 3 choices
      </button>
      <button type="button" fxFlex (click)="onAddChoice.emit(); onAddChoice.emit(); onAddChoice.emit(); onAddChoice.emit();" mat-stroked-button color="primary">
        Add 4 choices
      </button>
    </div>
  </div>
</div>

<div>
  <br>
  <br>
  <br>
  <br>
  <div>

    Correction<br>
     <editor [inline]="false" [init]="{
        plugins: 'quickbars code paste',
        paste_enable_default_filters: false,
        quickbars_selection_toolbar: 'bold italic',
        height: 200,
        paste_preprocess: cleanHTML
      }"
      apiKey="snod8dnubkfw3k1c2t1yg26pyqv25tehdqs25a3vdi52y4hx" [name]="'correctin' + uniq + '_'" [(ngModel)]="question.correction">
      </editor>
  </div>

</div>

<div>
    <br>
    <mat-form-field>
      <mat-label>Lessons</mat-label>
      <mat-select [compareWith]="compare" multiple [(ngModel)]="question.lessons" [name]="'lessons' + '_' + uniq">
        <mat-option *ngFor="let lesson of lessons" [value]="lesson">
          {{lesson}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>

<div>
  <mat-form-field class="example-full-width">
    <input matInput type="number" step="0.5" [placeholder]="'Value for the question'" [name]="'value_quesstoin_' + uniq" [(ngModel)]="question.value">
  </mat-form-field>
</div>

