import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { data } from './exam';

import * as _ from 'underscore';
import { CookieService } from 'ngx-cookie-service';

export interface Choice {
  text: string;
  is_correct: boolean;
}

export interface Question {
  text: string;
  correction?: string;
  choices: Choice[];
  lessons: string[];
  value?: number;
}

export interface Support {
  notes?: string;
  exam_audio?: string;
  training_audio?: string;
  text?: string;
  image?: string,
  part?: number,
  questions: Question[];
}


export interface Exam {
  support: Support[]
}

@Component({
  selector: 'polylearn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'integration';

  selectedSupport = 0;

  get isValid(): boolean {
    const questions = this.data[this.selectedSupport].questions;
    let isCorrects = _.flatten(questions.map(
      question => question.choices.filter(
        choice => choice.is_correct
      )
    ));
    return (questions.length === isCorrects.length);
  }

  data = data;


  constructor(
    private cd: ChangeDetectorRef,
    private cookieService: CookieService,
  ) {}


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    let data = window.localStorage.getItem('data');
    console.log('data', data);
    if (data) {
      try {
        this.data = JSON.parse(data);
      } catch (e) {

      }
    }
  }

  public saveData() {
    console.log('saveData', this.data);
    if (this.data) {
      window.localStorage.setItem('data', JSON.stringify(this.data));
    }
  }

  public addSupport() {
    const initialSupportState = {
      part: null,
      exam_audio: null,
      questions: [
        {
          correction: null,
          text: null,
          lessons: [],
          choices: [
            {
              is_correct: false,
              text: null
            },
            {
              is_correct: false,
              text: null
            },
            {
              is_correct: false,
              text: null
            }
          ]
        }
      ],
      image: null,
      text: null,
      notes: null,
      training_audio: null,
    };

    this.data.splice(this.selectedSupport + 1, 0, initialSupportState);
    console.log('thu', this.data);
    this.cd.detectChanges();

    this.saveData();
  }

  public reloadData($event: any) {
    try {
      console.log('reloadData');
      this.data = JSON.parse($event);
    } catch (e) {
      return;
    }

    this.saveData();
  }


  public removeQuestion(index: number) {
    this.data[this.selectedSupport].questions.splice(index, 1);
    this.cd.detectChanges();
    this.saveData();
  }

  public removeSupport() {
    this.data.splice(this.selectedSupport, 1);
    this.selectedSupport = this.selectedSupport - 1;
    this.cd.detectChanges();
    this.saveData();
  }

  public addQuestion() {
    let questions = this.data[this.selectedSupport].questions;
    this.data[this.selectedSupport].questions = [
      ...questions,
      {
        correction: null,
        text: null,
        lessons: [],
        choices: []
      }
    ];
    this.cd.detectChanges();
    this.saveData();
  }


  public addChoice(questionIndex: number) {
    let question = this.data[this.selectedSupport].questions[questionIndex];
    question.choices = [
      ...question.choices,
      {
        is_correct: false,
        text: null
      }
    ];
    this.cd.detectChanges();
    this.saveData();
  }

  public removeChoice(options: {questionIndex: number, choiceIndex: number}) {
    this.data[this.selectedSupport].questions[options.questionIndex].choices.splice(options.choiceIndex, 1);
    this.cd.detectChanges();
    this.saveData();
  }




}
