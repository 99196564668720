import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { QuillModule } from 'ngx-quill';
import { MarkdownModule } from 'ngx-markdown';
import { SimplemdeModule } from 'ngx-simplemde';
import { CookieService } from 'ngx-cookie-service';
import { EditorModule } from '@tinymce/tinymce-angular';


import { AppComponent } from './app.component';
import { SupportComponent } from './edit/support/support.component';
import { QuestionComponent } from './edit/question/question.component';
import { QuestionsComponent } from './edit/questions/questions.component';
import { JsonResultComponent } from './edit/json-result/json-result.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AppComponent, SupportComponent, QuestionComponent, QuestionsComponent, JsonResultComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSortModule,
    MatSlideToggleModule,
    MatRippleModule,

    EditorModule,

    SimplemdeModule.forRoot({
      autosave: { enabled: true, uniqueId: 'MyUniqueID' }
    } as any),
    QuillModule.forRoot(),
    MarkdownModule.forRoot(),
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
