import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'polylearn-json-result',
  templateUrl: './json-result.component.html',
  styleUrls: ['./json-result.component.css']
})
export class JsonResultComponent implements OnInit {
  @Input() data: any[];
  @Output() onUpdate = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
