import { Support } from './app.component';

export const data: Support[] = [

    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhat ## doing for the summer vacation?",
                "correction": null,
                "choices": [
                    {
                        "text": "are Paul",
                        "is_correct": false
                    },
                    {
                        "text": "Paul is",
                        "is_correct": false
                    },
                    {
                        "text": "is Paul",
                        "is_correct": true
                    },
                    {
                        "text": "does Paul",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present continuous/interrogative"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThe suppliers are  waiting for you ## the conference room.",
                "correction": null,
                "choices": [
                    {
                        "text": "in",
                        "is_correct": true
                    },
                    {
                        "text": "into",
                        "is_correct": false
                    },
                    {
                        "text": "at",
                        "is_correct": false
                    },
                    {
                        "text": "up",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Prepositions/place"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nJane's three sons  ## for the same law firm.",
                "correction": null,
                "choices": [
                    {
                        "text": "working",
                        "is_correct": false
                    },
                    {
                        "text": "works",
                        "is_correct": false
                    },
                    {
                        "text": "work",
                        "is_correct": true
                    },
                    {
                        "text": "is working",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present simple/affirmative"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nMargaret wants ## to organize the meeting.",
                "correction": null,
                "choices": [
                    {
                        "text": "his",
                        "is_correct": false
                    },
                    {
                        "text": "they",
                        "is_correct": false
                    },
                    {
                        "text": "us",
                        "is_correct": true
                    },
                    {
                        "text": "we",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Personal pronouns/object"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nDo they work on Sundays? ##.",
                "correction": null,
                "choices": [
                    {
                        "text": "Yes, they work",
                        "is_correct": false
                    },
                    {
                        "text": "No, they don't",
                        "is_correct": true
                    },
                    {
                        "text": "No, they don't work",
                        "is_correct": false
                    },
                    {
                        "text": "No, they are  not",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Short answers/present simple"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhere is the meeting room? \"It's ## on the right.\" ",
                "correction": null,
                "choices": [
                    {
                        "text": "two ",
                        "is_correct": false
                    },
                    {
                        "text": "the second ",
                        "is_correct": true
                    },
                    {
                        "text": "the two ",
                        "is_correct": false
                    },
                    {
                        "text": "second",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Dates/ordinal numbers"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text: \n## the conference last week?",
                "correction": null,
                "choices": [
                    {
                        "text": "Did he attend",
                        "is_correct": true
                    },
                    {
                        "text": "Has he attended",
                        "is_correct": false
                    },
                    {
                        "text": "Has he been attending",
                        "is_correct": false
                    },
                    {
                        "text": "Attended he",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Simple past/interrogative"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhose coat is this? It's ##.",
                "correction": null,
                "choices": [
                    {
                        "text": "me",
                        "is_correct": false
                    },
                    {
                        "text": "to me",
                        "is_correct": false
                    },
                    {
                        "text": "mine",
                        "is_correct": true
                    },
                    {
                        "text": "her",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Personal pronouns/possessive"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nPlease tell Steve and Amy that it's Peter Ferguson who  ## tomorrow’s meeting, so they had better arrive on time.",
                "correction": null,
                "choices": [
                    {
                        "text": "chairing",
                        "is_correct": false
                    },
                    {
                        "text": "going to chair",
                        "is_correct": false
                    },
                    {
                        "text": "is chairing",
                        "is_correct": true
                    },
                    {
                        "text": "chaired",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present continuous  - future"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI'm not sure that the new bridge is as sturdy ## the old one.",
                "correction": null,
                "choices": [
                    {
                        "text": "than",
                        "is_correct": false
                    },
                    {
                        "text": "as",
                        "is_correct": true
                    },
                    {
                        "text": "that",
                        "is_correct": false
                    },
                    {
                        "text": "what",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "As/than: comparatives"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThe flight is about   ##long  and  it's another hour from the airport to the office.",
                "correction": null,
                "choices": [
                    {
                        "text": "a hour ",
                        "is_correct": false
                    },
                    {
                        "text": "an hour ",
                        "is_correct": true
                    },
                    {
                        "text": "hour",
                        "is_correct": false
                    },
                    {
                        "text": "the hour ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The indefinite article"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nJim  ## the ceiling  when he fell off the ladder and broke his arm. ",
                "correction": null,
                "choices": [
                    {
                        "text": "painted",
                        "is_correct": false
                    },
                    {
                        "text": "was painting",
                        "is_correct": true
                    },
                    {
                        "text": "has painted ",
                        "is_correct": false
                    },
                    {
                        "text": "has been painting",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "simple past/past continuous"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThere are a lot of red scarves here.  Have a look and tell me ## one is yours.",
                "correction": null,
                "choices": [
                    {
                        "text": "which",
                        "is_correct": true
                    },
                    {
                        "text": "what",
                        "is_correct": false
                    },
                    {
                        "text": "how",
                        "is_correct": false
                    },
                    {
                        "text": "how many",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Relative pronouns "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nUnfortunately, we ## to contact him before he went to the airport. He's on the flight now, so we'll have to go ahead with the meeting. ",
                "correction": null,
                "choices": [
                    {
                        "text": "couldn't ",
                        "is_correct": false
                    },
                    {
                        "text": "weren't able",
                        "is_correct": true
                    },
                    {
                        "text": "cannot",
                        "is_correct": false
                    },
                    {
                        "text": "may not",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Be able to/can"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nYou ## find the noise of the machines hard to tolerate to begin with, but you will soon get used to it.",
                "correction": null,
                "choices": [
                    {
                        "text": "can",
                        "is_correct": false
                    },
                    {
                        "text": "must ",
                        "is_correct": false
                    },
                    {
                        "text": "may",
                        "is_correct": true
                    },
                    {
                        "text": "should have",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Modals"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI wonder ##. ",
                "correction": null,
                "choices": [
                    {
                        "text": "where is that new restaurant he mentioned",
                        "is_correct": false
                    },
                    {
                        "text": "where that new restaurant he mentioned is ",
                        "is_correct": true
                    },
                    {
                        "text": "how is that new restaurant he mentioned ",
                        "is_correct": false
                    },
                    {
                        "text": "where he mentioned that new restaurant is ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Indirect questions "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nAs requested, you will find ## the updated price list. ",
                "correction": null,
                "choices": [
                    {
                        "text": "attaching",
                        "is_correct": false
                    },
                    {
                        "text": "attached",
                        "is_correct": true
                    },
                    {
                        "text": "attach",
                        "is_correct": false
                    },
                    {
                        "text": "enclose",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Expressions for letters/emails "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThe people  ## she was speaking are the prospective buyers.",
                "correction": null,
                "choices": [
                    {
                        "text": "who",
                        "is_correct": false
                    },
                    {
                        "text": "that",
                        "is_correct": false
                    },
                    {
                        "text": "to whom",
                        "is_correct": true
                    },
                    {
                        "text": "to who",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Relative pronouns "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nHe works ## when he has no deadline than when has to meet a cut-off date.  The stress seems to get to him.",
                "correction": null,
                "choices": [
                    {
                        "text": "hard ",
                        "is_correct": false
                    },
                    {
                        "text": "harder",
                        "is_correct": true
                    },
                    {
                        "text": "hardly",
                        "is_correct": false
                    },
                    {
                        "text": "hardest",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Adjectives/comparative"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nPlease ask ## tomorrow's meeting.  There is a blizzard on the way and I'm not sure everyone will be able to make it.  ",
                "correction": null,
                "choices": [
                    {
                        "text": "to Anne cancel",
                        "is_correct": false
                    },
                    {
                        "text": "Anne to cancel",
                        "is_correct": true
                    },
                    {
                        "text": "to Anne to cancel",
                        "is_correct": false
                    },
                    {
                        "text": "Anne cancelling",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Ask someone to do something "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nWhat is wrong with my laptop? It hasn't been working properly ## it came back from the repair shop. ",
                "correction": null,
                "choices": [
                    {
                        "text": "during",
                        "is_correct": false
                    },
                    {
                        "text": "since",
                        "is_correct": true
                    },
                    {
                        "text": "for ",
                        "is_correct": false
                    },
                    {
                        "text": "ago",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "For /since"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nLady Gaga is ##, among other things, her unconventionality and visual experimentation.",
                "correction": null,
                "choices": [
                    {
                        "text": "famous of",
                        "is_correct": false
                    },
                    {
                        "text": "known to",
                        "is_correct": false
                    },
                    {
                        "text": "famous for ",
                        "is_correct": true
                    },
                    {
                        "text": "celebrated to",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Adjective plus preposition"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nYou will receive a complimentary lunch if you ## before midday ",
                "correction": null,
                "choices": [
                    {
                        "text": "arrived",
                        "is_correct": false
                    },
                    {
                        "text": "would arrive",
                        "is_correct": false
                    },
                    {
                        "text": "arrive",
                        "is_correct": true
                    },
                    {
                        "text": "will arrive ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The first conditional "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nPat ## a really good job.  We should definitely offer her a permanent contract.",
                "correction": null,
                "choices": [
                    {
                        "text": "is making",
                        "is_correct": false
                    },
                    {
                        "text": "is doing",
                        "is_correct": true
                    },
                    {
                        "text": "made",
                        "is_correct": false
                    },
                    {
                        "text": "done",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Make / do "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nDid you know that  I## work for our competitors?  I'm so glad I made the move and came here.",
                "correction": null,
                "choices": [
                    {
                        "text": "usual",
                        "is_correct": false
                    },
                    {
                        "text": "am used to",
                        "is_correct": false
                    },
                    {
                        "text": "was used to",
                        "is_correct": false
                    },
                    {
                        "text": "used to",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "Used  to/ be used to "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nNeither Jack ## his brother Jim graduated from high school but they've both done really well for themselves.",
                "correction": null,
                "choices": [
                    {
                        "text": "or",
                        "is_correct": false
                    },
                    {
                        "text": "and",
                        "is_correct": false
                    },
                    {
                        "text": " nor",
                        "is_correct": true
                    },
                    {
                        "text": "neither ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Both, either, neither"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI was very happy with my stay in the hospital.  I received all the ## I needed.",
                "correction": null,
                "choices": [
                    {
                        "text": "careful",
                        "is_correct": false
                    },
                    {
                        "text": "care",
                        "is_correct": true
                    },
                    {
                        "text": "carefully",
                        "is_correct": false
                    },
                    {
                        "text": "cares",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Nouns"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhere ## those glasses? They look great on you.",
                "correction": null,
                "choices": [
                    {
                        "text": "have you bought",
                        "is_correct": false
                    },
                    {
                        "text": "did you buy",
                        "is_correct": true
                    },
                    {
                        "text": "you bought",
                        "is_correct": false
                    },
                    {
                        "text": "have you been buying ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Simple past/interrogative"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThey## any profit since the merger took place three months ago.",
                "correction": null,
                "choices": [
                    {
                        "text": "aren't making",
                        "is_correct": false
                    },
                    {
                        "text": "haven't made",
                        "is_correct": true
                    },
                    {
                        "text": "didn't make",
                        "is_correct": false
                    },
                    {
                        "text": "don't make",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The present perfect "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\n## to the wedding if you are invited?",
                "correction": null,
                "choices": [
                    {
                        "text": "Do you come",
                        "is_correct": false
                    },
                    {
                        "text": "Would you have come",
                        "is_correct": false
                    },
                    {
                        "text": "Would you come",
                        "is_correct": false
                    },
                    {
                        "text": "Will you come",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "First conditional"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWe assemble the machines but the parts ## abroad.",
                "correction": null,
                "choices": [
                    {
                        "text": "are manufactured",
                        "is_correct": true
                    },
                    {
                        "text": "is manufactured ",
                        "is_correct": false
                    },
                    {
                        "text": "have manufactured",
                        "is_correct": false
                    },
                    {
                        "text": "has manufactured",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Passive form (present) "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nThe dollar  has risen ## the yen again today.",
                "correction": null,
                "choices": [
                    {
                        "text": "from",
                        "is_correct": false
                    },
                    {
                        "text": "by",
                        "is_correct": false
                    },
                    {
                        "text": "of",
                        "is_correct": false
                    },
                    {
                        "text": "against",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "Prepositions "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI wouldn't do it if I ## you.  It's just too risky.",
                "correction": null,
                "choices": [
                    {
                        "text": "were",
                        "is_correct": true
                    },
                    {
                        "text": "would have been",
                        "is_correct": false
                    },
                    {
                        "text": "will be ",
                        "is_correct": false
                    },
                    {
                        "text": "was being",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The 2nd conditional"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\n Ask the receptionist to phone me when you ## at reception.  I'll come to meet you in the hall.",
                "correction": null,
                "choices": [
                    {
                        "text": "will arrive",
                        "is_correct": false
                    },
                    {
                        "text": "arrive",
                        "is_correct": true
                    },
                    {
                        "text": "arriving",
                        "is_correct": false
                    },
                    {
                        "text": "would arrive",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    " When /as soon as + present"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI'd like to go to Seville.  I wonder if it's ## from here?",
                "correction": null,
                "choices": [
                    {
                        "text": "long",
                        "is_correct": false
                    },
                    {
                        "text": "long distance",
                        "is_correct": false
                    },
                    {
                        "text": "far ",
                        "is_correct": true
                    },
                    {
                        "text": "much time",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "(How) far from "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nShe hates it here.  The weather is ## cold in winter.",
                "correction": null,
                "choices": [
                    {
                        "text": "too",
                        "is_correct": true
                    },
                    {
                        "text": "too much",
                        "is_correct": false
                    },
                    {
                        "text": "so much",
                        "is_correct": false
                    },
                    {
                        "text": "too much",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The adverb too"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThe local election board bought those electronic voting machines ## the federal government warned them that the machines could be hacked.",
                "correction": null,
                "choices": [
                    {
                        "text": "in spite of ",
                        "is_correct": false
                    },
                    {
                        "text": "even though",
                        "is_correct": true
                    },
                    {
                        "text": "despite",
                        "is_correct": false
                    },
                    {
                        "text": "even",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Although/despite"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nOil prices will probably go up again  ## the trade war.",
                "correction": null,
                "choices": [
                    {
                        "text": "because",
                        "is_correct": false
                    },
                    {
                        "text": "resulted in ",
                        "is_correct": false
                    },
                    {
                        "text": "because of",
                        "is_correct": true
                    },
                    {
                        "text": "due",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Conjunctions"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWe are really looking forward to ## on holiday.",
                "correction": null,
                "choices": [
                    {
                        "text": "be",
                        "is_correct": false
                    },
                    {
                        "text": "being",
                        "is_correct": true
                    },
                    {
                        "text": "will  be",
                        "is_correct": false
                    },
                    {
                        "text": "have been",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "The gerundive form"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWill they agree  ## the meeting until we get the quarterly results?",
                "correction": null,
                "choices": [
                    {
                        "text": "postpone ",
                        "is_correct": false
                    },
                    {
                        "text": "postponed ",
                        "is_correct": false
                    },
                    {
                        "text": "postponing",
                        "is_correct": false
                    },
                    {
                        "text": "to postpone ",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "Full infinitive"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nYou should have  ## us the technician was coming.",
                "correction": null,
                "choices": [
                    {
                        "text": "told",
                        "is_correct": true
                    },
                    {
                        "text": "tell",
                        "is_correct": false
                    },
                    {
                        "text": "said  ",
                        "is_correct": false
                    },
                    {
                        "text": "telling",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Say/tell"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text: \n## meeting after meeting is not the solution. Let's just get on with our work and see where we are in two weeks.",
                "correction": null,
                "choices": [
                    {
                        "text": "To having",
                        "is_correct": false
                    },
                    {
                        "text": "Have",
                        "is_correct": false
                    },
                    {
                        "text": "Having",
                        "is_correct": true
                    },
                    {
                        "text": "For have",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Participle clause "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nIt rained very ## this morning and all my plants are destroyed. ",
                "correction": null,
                "choices": [
                    {
                        "text": "hard ",
                        "is_correct": true
                    },
                    {
                        "text": "harder",
                        "is_correct": false
                    },
                    {
                        "text": "hardly ",
                        "is_correct": false
                    },
                    {
                        "text": "hardest ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Adverbs of manner"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nThere is food and drink in the next room.  Help ## to anything you want.  The meeting will start in 45 minutes, so you have lots of time. ",
                "correction": null,
                "choices": [
                    {
                        "text": "itself",
                        "is_correct": false
                    },
                    {
                        "text": "yourself",
                        "is_correct": true
                    },
                    {
                        "text": "himself",
                        "is_correct": false
                    },
                    {
                        "text": "herself",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Reflexive pronouns"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI don't care who you are.  You are not coming in here ## you show some form of identification.",
                "correction": null,
                "choices": [
                    {
                        "text": "even ",
                        "is_correct": false
                    },
                    {
                        "text": "although",
                        "is_correct": false
                    },
                    {
                        "text": "unless",
                        "is_correct": true
                    },
                    {
                        "text": "in order to",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Conjunctions"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI would like to speak to the person who  ## company accounts.",
                "correction": null,
                "choices": [
                    {
                        "text": "handle",
                        "is_correct": false
                    },
                    {
                        "text": "deals with",
                        "is_correct": true
                    },
                    {
                        "text": "in charge of",
                        "is_correct": false
                    },
                    {
                        "text": "treats",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Vocabulary"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nWe ## for being so late.  There was a serious crash on the motorway and traffic was at a total standstill.",
                "correction": null,
                "choices": [
                    {
                        "text": "forgive",
                        "is_correct": false
                    },
                    {
                        "text": "excuse",
                        "is_correct": false
                    },
                    {
                        "text": "apologize",
                        "is_correct": true
                    },
                    {
                        "text": "pardon",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Apologizing "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nThey're not going to meet the deadline.  They ## used the help of the interns when they had the chance.",
                "correction": null,
                "choices": [
                    {
                        "text": "need to",
                        "is_correct": false
                    },
                    {
                        "text": "must ",
                        "is_correct": false
                    },
                    {
                        "text": "should have",
                        "is_correct": true
                    },
                    {
                        "text": "ought to",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Modality "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nExcuse me, ## you tell me where the post office is?",
                "correction": null,
                "choices": [
                    {
                        "text": "could",
                        "is_correct": true
                    },
                    {
                        "text": "would",
                        "is_correct": false
                    },
                    {
                        "text": "I'd like",
                        "is_correct": false
                    },
                    {
                        "text": "should",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Polite requests"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nA new power station is ## to meet the increase in demand.",
                "correction": null,
                "choices": [
                    {
                        "text": "built",
                        "is_correct": false
                    },
                    {
                        "text": "been built",
                        "is_correct": false
                    },
                    {
                        "text": "being built",
                        "is_correct": true
                    },
                    {
                        "text": "be built",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Passive forms"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nPlease bring your  ## to the meeting. I need you to take the minutes. ",
                "correction": null,
                "choices": [
                    {
                        "text": "notepad",
                        "is_correct": false
                    },
                    {
                        "text": "laptop",
                        "is_correct": true
                    },
                    {
                        "text": "calculator",
                        "is_correct": false
                    },
                    {
                        "text": "mainframe",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Vocabulary"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nIf we had known what a fraud he was  we ## anything to do with his company.",
                "correction": null,
                "choices": [
                    {
                        "text": "wouldn't have had",
                        "is_correct": true
                    },
                    {
                        "text": "wouldn't have",
                        "is_correct": false
                    },
                    {
                        "text": "hadn't have",
                        "is_correct": false
                    },
                    {
                        "text": "will not have",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "3rd conditional"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhy ## to go to that conference?  It looks like it's just the same old talking points.",
                "correction": null,
                "choices": [
                    {
                        "text": "are you wanting",
                        "is_correct": false
                    },
                    {
                        "text": "you want ",
                        "is_correct": false
                    },
                    {
                        "text": "do you want ",
                        "is_correct": true
                    },
                    {
                        "text": "does you want",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present /non-progressive verbs"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Complete the sentence with the most suitable word:\nHe is a very ## person. He hates losing at anything, but it just makes him work even harder.",
                "correction": null,
                "choices": [
                    {
                        "text": "competition",
                        "is_correct": false
                    },
                    {
                        "text": "competing",
                        "is_correct": false
                    },
                    {
                        "text": "competitive",
                        "is_correct": true
                    },
                    {
                        "text": "compete",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Adjectives"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nWhat's keeping the mail man? We ## for that registered letter for almost two hours.  We won't be able to continue the discussions without it.",
                "correction": null,
                "choices": [
                    {
                        "text": "has waited",
                        "is_correct": false
                    },
                    {
                        "text": "waited",
                        "is_correct": false
                    },
                    {
                        "text": "have been waiting",
                        "is_correct": true
                    },
                    {
                        "text": "had waited",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present perfect continuous"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI'm sure he'll get re-elected. He## president since 2015 and he's still as popular as ever.",
                "correction": null,
                "choices": [
                    {
                        "text": "was",
                        "is_correct": false
                    },
                    {
                        "text": "has been",
                        "is_correct": true
                    },
                    {
                        "text": "is",
                        "is_correct": false
                    },
                    {
                        "text": "have been ",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Present perfect "
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nJust understand that they cannot make you ## in those conditions.  It's against the law.",
                "correction": null,
                "choices": [
                    {
                        "text": "working",
                        "is_correct": false
                    },
                    {
                        "text": "work",
                        "is_correct": true
                    },
                    {
                        "text": "to work",
                        "is_correct": false
                    },
                    {
                        "text": "worked",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Infinitive without \" to\""
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nI have ## free time on my hands at the moment, so if you need help just let me know.",
                "correction": null,
                "choices": [
                    {
                        "text": "many ",
                        "is_correct": false
                    },
                    {
                        "text": "a few",
                        "is_correct": false
                    },
                    {
                        "text": " few ",
                        "is_correct": false
                    },
                    {
                        "text": "a lot of ",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "Determiners and quantifiers"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nNo, I don't think she's broke.  ## , remember that money she got from her parents just a short while ago.  What's more, she's just been promoted, so I presume that comes with a salary hike. ",
                "correction": null,
                "choices": [
                    {
                        "text": "For a start",
                        "is_correct": true
                    },
                    {
                        "text": "Starting off",
                        "is_correct": false
                    },
                    {
                        "text": "Next",
                        "is_correct": false
                    },
                    {
                        "text": "On top of that",
                        "is_correct": false
                    }
                ],
                "lessons": [
                    "Discourse markers"
                ]
            }
        ]
    },
    {
        "exam_audio": "",
        "training_audio": "",
        "text": "",
        "image": null,
        "part": 2,
        "questions": [
            {
                "text": "Choose the correct item to complete the text:\nYou've ## Sally.  Well done.  Of course the problem must be cross-contamination. It's the only logical explanation.",
                "correction": null,
                "choices": [
                    {
                        "text": "added insult to injury",
                        "is_correct": false
                    },
                    {
                        "text": "let the cat out of the bag",
                        "is_correct": false
                    },
                    {
                        "text": "barked up the wrong tree",
                        "is_correct": false
                    },
                    {
                        "text": "hit the nail on the head",
                        "is_correct": true
                    }
                ],
                "lessons": [
                    "Idioms"
                ]
            }
        ]
    },
    {
        "exam_audio": "1-1.mp3",
        "training_audio": "1-1.mp3",
        "text": "Man: Could you send me a catalogue, please?\nWoman: Oh, I'm sorry, did you not receive the one I sent last week?\nMan: No… but we have moved premises recently.  That probably explains why it didn't arrive\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What might explain why the catalogue has not arrived?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "It was never sent.",
                        "is_correct": false
                    },
                    {
                        "text": "It was sent to the wrong address.",
                        "is_correct": true
                    },
                    {
                        "text": "There were no stamps on the packet. ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "2-1.mp3",
        "training_audio": "2-1.mp3",
        "text": "Woman: Have you finished writing that report yet?\nMan: I'm still working on the final draft.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He has finished the report. ",
                        "is_correct": false
                    },
                    {
                        "text": "He has not finished the report.",
                        "is_correct": true
                    },
                    {
                        "text": "He probably won't finish the report on time.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "3-1.mp3",
        "training_audio": "3-1.mp3",
        "text": "Man: Do you have a number for them?\nWoman: Yes, it's 0774 397 492\nWoman: Do you have a double room for the weekend of the 12th?",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "The phone number is:\n",
                "correction": null,
                "choices": [
                    {
                        "text": "*0774 397 492",
                        "is_correct": true
                    },
                    {
                        "text": "*0774 397 499",
                        "is_correct": false
                    },
                    {
                        "text": "*0274 397 492",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "4-1.mp3",
        "training_audio": "4-1.mp3",
        "text": "Woman: I'll meet you at the hotel at quarter past seven.\nMan: I can't make it that early.  What about 45 minutes later than that?\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What time does the man want to meet?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "7.30",
                        "is_correct": false
                    },
                    {
                        "text": "7.45",
                        "is_correct": false
                    },
                    {
                        "text": "8.00",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "5-1.mp3",
        "training_audio": "5-1.mp3",
        "text": "Man: Are there any seats available for next Friday?\nWoman: Only in the upper circle.\nMan: I was hoping for something a bit closer to the stage.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What is the man trying to do?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Book tickets for a show.",
                        "is_correct": true
                    },
                    {
                        "text": "Change some tickets he has bought.",
                        "is_correct": false
                    },
                    {
                        "text": "Find out what day a performance is taking place.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "6-1.mp3",
        "training_audio": "6-1.mp3",
        "text": "Man: We're going to be short-staffed over the holiday period.\nWoman: We should think about taking on some extra workers.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Some employees won't need to come into work during the holiday period.",
                        "is_correct": false
                    },
                    {
                        "text": "There won't be enough workers during the holiday period.",
                        "is_correct": true
                    },
                    {
                        "text": "There will be a shortage of supplies over the holiday period.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "7-1.mp3",
        "training_audio": "7-1.mp3",
        "text": "Man: It would be quicker to take the night-bus than wait here for a taxi.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He would prefer to take a taxi.",
                        "is_correct": false
                    },
                    {
                        "text": "The bus is faster than a taxi.",
                        "is_correct": true
                    },
                    {
                        "text": "It would be quicker to walk.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "8-1.mp3",
        "training_audio": "8-1.mp3",
        "text": "Man: Did you manage to get the proposal in on time?\nWoman: I've had to ask them to extend the deadline.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "She needs more time to finish her task.",
                        "is_correct": true
                    },
                    {
                        "text": "She has requested help from the manager",
                        "is_correct": false
                    },
                    {
                        "text": "She is obliged to meet the original deadline.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "9-1.mp3",
        "training_audio": "9-1.mp3",
        "text": "Man: These feel a bit loose round the waist - do have the next size down?\nWoman: Let me go and check.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What problem does the man have? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "He thinks he should lose some weight.",
                        "is_correct": false
                    },
                    {
                        "text": "He needs to dispose of some waste.",
                        "is_correct": false
                    },
                    {
                        "text": "An item of clothing is too big.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "10-1.mp3",
        "training_audio": "10-1.mp3",
        "text": "Woman: Have you had the wooden flooring put down yet?\nMan: No - finding skilled craftsmen is not as easy as you'd think.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "The work was completed, but not to his satisfaction.",
                        "is_correct": false
                    },
                    {
                        "text": "He has not found anyone who can do the work.",
                        "is_correct": true
                    },
                    {
                        "text": "It is not difficult to find competent workers.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "11-1.mp3",
        "training_audio": "11-1.mp3",
        "text": "Woman: Now that I'm running my own business, I hardly ever take time off.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "She likes to go running after work.",
                        "is_correct": false
                    },
                    {
                        "text": "It is easy to take a holiday when you are self-employed.",
                        "is_correct": false
                    },
                    {
                        "text": "It is difficult to have any free time when you are self-employed.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "12-1.mp3",
        "training_audio": "12-1.mp3",
        "text": "Woman: Your case is too big to take on as hand luggage.\nMan: What if I repack it?\nWoman: I'm afraid that won't make any difference.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Where does this conversation take place?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "At an airport",
                        "is_correct": true
                    },
                    {
                        "text": "At a railway station.",
                        "is_correct": false
                    },
                    {
                        "text": "At a bus station.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "13-1.mp3",
        "training_audio": "13-1.mp3",
        "text": "Man: Has the meeting been put back then?\nWoman: Only by an hour.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The meeting will take place at 1 o'clock.",
                        "is_correct": false
                    },
                    {
                        "text": "The meeting was cancelled at the last minute.",
                        "is_correct": false
                    },
                    {
                        "text": "The meeting will take place an hour later than planned.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "14-1.mp3",
        "training_audio": "14-1.mp3",
        "text": "Woman: The damage to the car isn't covered by the insurance.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The insurance company will not pay for the damage to the car.",
                        "is_correct": true
                    },
                    {
                        "text": "The car was not badly damaged.",
                        "is_correct": false
                    },
                    {
                        "text": "The insurance company will cover the cost of repairing the car.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "15-1.mp3",
        "training_audio": "15-1.mp3",
        "text": "Man: When are they going to do something about the traffic - it's always backed-up at the crossroads?\nWoman: Don't worry - city planners are redesigning the layout of the whole junction. Work is scheduled to start this summer.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "City planners have a back-up plan to reduce traffic during high season.",
                        "is_correct": false
                    },
                    {
                        "text": "City planners are not doing enough to limit traffic at the junction.",
                        "is_correct": false
                    },
                    {
                        "text": "City planners are going to improve the crossroads.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "16-1.mp3",
        "training_audio": "16-1.mp3",
        "text": "Man: I had to have the hard drive on my laptop replaced.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does  the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Someone else fixed his computer.",
                        "is_correct": true
                    },
                    {
                        "text": "He fixed the computer himself.",
                        "is_correct": false
                    },
                    {
                        "text": "He had to buy a new computer.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "17-1.mp3",
        "training_audio": "17-1.mp3",
        "text": "Man: The director's name is Jim Macintyre, that's M-A-C-I-N-T-Y-R-E. ( spell at normal speed)\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What is the correct spelling? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "Jim Macintire",
                        "is_correct": false
                    },
                    {
                        "text": "Jim Macyntyre",
                        "is_correct": false
                    },
                    {
                        "text": "Jim Macintyre",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "18-1.mp3",
        "training_audio": "18-1.mp3",
        "text": "Woman: Have you found a cheaper ticket to New York?\nMan: Yes, but although the fare is less expensive, there's a four-hour stop-over in Amsterdam.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The flight to NY is not direct.",
                        "is_correct": true
                    },
                    {
                        "text": "It would be better to travel via Amsterdam.",
                        "is_correct": false
                    },
                    {
                        "text": "The price of the ticket is too expensive.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "19-1.mp3",
        "training_audio": "19-1.mp3",
        "text": "Woman: Have you been in touch with Mrs Hobson?\nMan: Yes, she wants you to get back to her asap with an estimate.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "That Mrs Hobson will contact the woman shortly.",
                        "is_correct": false
                    },
                    {
                        "text": "That Mrs Hobson has received the information she wanted.",
                        "is_correct": false
                    },
                    {
                        "text": "That the woman should contact Mrs Hobson.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "20-1.mp3",
        "training_audio": "20-1.mp3",
        "text": "The total is €19 714, which includes €1775 for VAT and €275 for shipping.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What is the combined cost of VAT and shipping? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "2000",
                        "is_correct": true
                    },
                    {
                        "text": "19714",
                        "is_correct": false
                    },
                    {
                        "text": "275",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "21-1.mp3",
        "training_audio": "21-1.mp3",
        "text": "Man: Hi Sophie, what's up?\nWoman: Hi Chris.  Are you coming to the conference next week? I need an answer by Friday.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "That Chris should confirm if he is attending the conference.  ",
                        "is_correct": true
                    },
                    {
                        "text": "That Chris should give a talk at the conference. ",
                        "is_correct": false
                    },
                    {
                        "text": "That Chris should go to the conference on Friday.  ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "22-1.mp3",
        "training_audio": "22-1.mp3",
        "text": "Woman: You know they're going to impose a new toll on anyone driving into the city centre?\nMan: I couldn't care less - I always take public transport.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "That he is not concerned by the new toll.",
                        "is_correct": true
                    },
                    {
                        "text": "That from now on he is going to take public transport.",
                        "is_correct": false
                    },
                    {
                        "text": "That he is going to take care to avoid the city centre.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "23-1.mp3",
        "training_audio": "23-1.mp3",
        "text": "Man: We only received a third of the original shipment - there were supposed to be 18 multi-packs.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "How many multi-packs were delivered?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "6",
                        "is_correct": true
                    },
                    {
                        "text": "18",
                        "is_correct": false
                    },
                    {
                        "text": "12",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "24-1.mp3",
        "training_audio": "24-1.mp3",
        "text": "Man: I don't know why they've put the toner cartridges on the top shelf. Would you mind lending me a hand?\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What would be the best response to the question? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "Sure.",
                        "is_correct": true
                    },
                    {
                        "text": "I'm not sure.",
                        "is_correct": false
                    },
                    {
                        "text": "Yes, I would.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "25-1.mp3",
        "training_audio": "25-1.mp3",
        "text": "Woman: Do you have a double room for the weekend of the 12th?\nMan: I'm afraid we're fully booked.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "There are no rooms available. ",
                        "is_correct": true
                    },
                    {
                        "text": "The man forgot to reserve a room. ",
                        "is_correct": false
                    },
                    {
                        "text": "There are a lot of rooms available. ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "26-1.mp3",
        "training_audio": "26-1.mp3",
        "text": "Man: We wouldn't have been late, if Jim hadn't insisted on going back for his glasses.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "They were late because Jim had forgotten something.",
                        "is_correct": true
                    },
                    {
                        "text": "They would have been late if Jim had decided to go back to collect something.",
                        "is_correct": false
                    },
                    {
                        "text": "They were late because Jim insisted on stopping to buy some glasses on the way.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "27-1.mp3",
        "training_audio": "27-1.mp3",
        "text": "Woman: Thompsons need to come up with a better offer before we go ahead and sign the deal.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "She will go ahead and sign the deal with Thompsons.",
                        "is_correct": false
                    },
                    {
                        "text": "Thompsons' offer is not acceptable at the moment.",
                        "is_correct": true
                    },
                    {
                        "text": "Thompsons are not prepared to make a better offer.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "28-1.mp3",
        "training_audio": "28-1.mp3",
        "text": "Woman: There's still the same glitch in the inventory control system.\nMan: Fred has been onto the IT department to try to get them to sort it out, but I guess so far they haven't managed to.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Fred has contacted the I.T. department, but they have not yet fixed the problem.",
                        "is_correct": true
                    },
                    {
                        "text": "Fred has not yet been able to contact the I.T. department.",
                        "is_correct": false
                    },
                    {
                        "text": "The I.T. department has succeeded in fixing the problem.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "29-1.mp3",
        "training_audio": "29-1.mp3",
        "text": "Man: They offered Sam the job of assistant sound engineer, but he turned it down.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Sam is thinking of accepting the job offer.",
                        "is_correct": false
                    },
                    {
                        "text": "Sam offered to turn down the sound.",
                        "is_correct": false
                    },
                    {
                        "text": "Sam decided not to accept the job offer.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "30-1.mp3",
        "training_audio": "30-1.mp3",
        "text": "Woman: You should take the shuttle bus to the airport - there are seldom any hold-ups.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker say?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The shuttle bus rarely gets delayed.",
                        "is_correct": true
                    },
                    {
                        "text": "The shuttle bus is not very reliable.",
                        "is_correct": false
                    },
                    {
                        "text": "The shuttle bus usually gets stuck in traffic.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "31-1.mp3",
        "training_audio": "31-1.mp3",
        "text": "Man: They were feeling tired, so they stopped to have a rest.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker say?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Even though they stopped for a rest, they still felt tired.",
                        "is_correct": false
                    },
                    {
                        "text": "Although they were tired, they decided not to have a rest.",
                        "is_correct": false
                    },
                    {
                        "text": "Because they were tired they decided to take a rest.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "32-1.mp3",
        "training_audio": "32-1.mp3",
        "text": "Man: Is this your jacket?\nWoman: No. It looks like mine, but mine’s got gold buttons. I this it belongs to Jack’s wife.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is correct?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The woman has no idea who the jacket belongs to. ",
                        "is_correct": false
                    },
                    {
                        "text": "The jacket belongs to the woman.  ",
                        "is_correct": false
                    },
                    {
                        "text": "The jacket may belong to Jack's wife.  ",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "33-1.mp3",
        "training_audio": "33-1.mp3",
        "text": "Man: I don't know whether to accept this internship or not.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He is not sure if he wants to do the internship.",
                        "is_correct": true
                    },
                    {
                        "text": "He is worried the weather will affect the internship.",
                        "is_correct": false
                    },
                    {
                        "text": "He has decided not to accept the internship.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "34-1.mp3",
        "training_audio": "34-1.mp3",
        "text": "Woman: I haven't heard back from Michelle yet…\nMan: Sorry about that Anne.  I'll have her call you, once she gets back to the office.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He does not know where Michelle is or what time she will be back.",
                        "is_correct": false
                    },
                    {
                        "text": "He will ask Michelle to contact Anne.",
                        "is_correct": true
                    },
                    {
                        "text": "He is going to call Michelle and give her a message. ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "35-1.mp3",
        "training_audio": "35-1.mp3",
        "text": "Man: If it weren't for his personal connections, Peter would never have got the job.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Peter does not have any personal connections who might otherwise have helped him get the job.",
                        "is_correct": false
                    },
                    {
                        "text": "Peter did not get the job, despite his personal connections.",
                        "is_correct": false
                    },
                    {
                        "text": "Peter only got the job because of his personal connections.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "36-1.mp3",
        "training_audio": "36-1.mp3",
        "text": "Woman: It's about time the shopping-mall was revamped.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The shopping mall needs renovating.",
                        "is_correct": true
                    },
                    {
                        "text": "The shopping mall should be demolished.",
                        "is_correct": false
                    },
                    {
                        "text": ": It is too late to save the shopping mall. ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "37-1.mp3",
        "training_audio": "37-1.mp3",
        "text": "Man: They stay open till 8 at the weekend, but on weekdays they close an hour earlier.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What time do they close on weekdays?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "7",
                        "is_correct": true
                    },
                    {
                        "text": "9",
                        "is_correct": false
                    },
                    {
                        "text": "8",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "38-1.mp3",
        "training_audio": "38-1.mp3",
        "text": "Woman: Can you give me a call next Wednesday morning - that's the 29th?\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "She wants the other person to call her next week.",
                        "is_correct": true
                    },
                    {
                        "text": "She wants the other person to call her back next Thursday. ",
                        "is_correct": false
                    },
                    {
                        "text": "She will call the other person back on the 28th.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "39-1.mp3",
        "training_audio": "39-1.mp3",
        "text": "Man: The food here is not as good as it used to be.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The establishment no longer serves food. ",
                        "is_correct": false
                    },
                    {
                        "text": "The food has got worse.",
                        "is_correct": true
                    },
                    {
                        "text": "The food has got better.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "40-1.mp3",
        "training_audio": "40-1.mp3",
        "text": "Woman: Did you remember to send in your expense receipts?\nMan: No.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He has forgotten to file his expense receipts.",
                        "is_correct": true
                    },
                    {
                        "text": "He does not need to send in his expense receipts.",
                        "is_correct": false
                    },
                    {
                        "text": "He does not remember if he has sent in his expense receipts.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "41-1.mp3",
        "training_audio": "41-1.mp3",
        "text": "Man: There's been a steady fall in the value of the pound over the past 12 months.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The pound has held steady during the last 12 months.",
                        "is_correct": false
                    },
                    {
                        "text": "The value of the pound has increased during the last 12 months.",
                        "is_correct": false
                    },
                    {
                        "text": "The value of the pound has decreased during the last 12 months.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "42-1.mp3",
        "training_audio": "42-1.mp3",
        "text": "Woman: Thank you for that Patrick. If we could move onto the last point on the agenda now.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "There is still one subject to discuss at the meeting. ",
                        "is_correct": true
                    },
                    {
                        "text": "The speaker wants to conclude the meeting.",
                        "is_correct": false
                    },
                    {
                        "text": "Patrick should change an appointment in his diary.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "43-1.mp3",
        "training_audio": "43-1.mp3",
        "text": "Man: There's too much at stake for us to pull out of the project now.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "They should cancel the project because it is too high risk.",
                        "is_correct": false
                    },
                    {
                        "text": "They should not invest in the project without considering the risks. ",
                        "is_correct": false
                    },
                    {
                        "text": "It would not be a good idea to withdraw from the project.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "44-1.mp3",
        "training_audio": "44-1.mp3",
        "text": "Man: During the demolition phase local residents will have to put up with noise pollution, dust and traffic disruption.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Residents have aired concerns about the demolition work.",
                        "is_correct": false
                    },
                    {
                        "text": "There will be little disruption caused by the demolition work.",
                        "is_correct": false
                    },
                    {
                        "text": "Residents will be severely affected by demolition work.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "45-1.mp3",
        "training_audio": "45-1.mp3",
        "text": "Woman: I wasn't sure whether to book well in advance or wait for a last-minute deal.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What is the best response to the statement?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "It's hard to know, isn't it?",
                        "is_correct": true
                    },
                    {
                        "text": "You should leave as soon as possible.",
                        "is_correct": false
                    },
                    {
                        "text": "There's no guarantee you'll get a ticket.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "46-1.mp3",
        "training_audio": "46-1.mp3",
        "text": "Man: Did you hear? - they've decided to abandon plans for the new airport.\nWoman: You're kidding?\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The man should not make jokes about such an important subject.",
                        "is_correct": false
                    },
                    {
                        "text": "It is a good joke.",
                        "is_correct": false
                    },
                    {
                        "text": "She cannot believe the news.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "47-1.mp3",
        "training_audio": "47-1.mp3",
        "text": "Man: What did you think of the motivational speaker?\nWoman: She couldn't have been better.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman say about the motivational speaker?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "She was excellent.",
                        "is_correct": true
                    },
                    {
                        "text": "She did not do a very good job.",
                        "is_correct": false
                    },
                    {
                        "text": "She did her best.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "48-1.mp3",
        "training_audio": "48-1.mp3",
        "text": "Man: I picked up this brand-new coffee-machine for €45.\nWoman: Bargain!\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The man should have negotiated a better price.",
                        "is_correct": false
                    },
                    {
                        "text": "The man got a good deal.",
                        "is_correct": true
                    },
                    {
                        "text": "The man should not have bought such a cheap coffee machine.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "49-1.mp3",
        "training_audio": "49-1.mp3",
        "text": "Man: How was the exhibition?\nWoman: After all the hype it failed to live up to expectations.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "It was disappointing.",
                        "is_correct": true
                    },
                    {
                        "text": "It was better than the advertising had led her to expect.",
                        "is_correct": false
                    },
                    {
                        "text": "It fulfilled all her expectations.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "50-1.mp3",
        "training_audio": "50-1.mp3",
        "text": "Woman: Please make sure you've completed the online passenger information form prior to departure.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "Passengers should register their personal details before travelling. ",
                        "is_correct": true
                    },
                    {
                        "text": "Passengers should submit an online form when they have completed their trip.",
                        "is_correct": false
                    },
                    {
                        "text": "Passengers will not be allowed to travel unless they have completed a form.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "51-1.mp3",
        "training_audio": "51-1.mp3",
        "text": "Man: How was New York?\nWoman: What with all the client meetings, I hardly had time to do any sightseeing.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman say? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "She had a hard time getting around New York.",
                        "is_correct": false
                    },
                    {
                        "text": "She would have liked to have had more time to visit New York.",
                        "is_correct": true
                    },
                    {
                        "text": "She was too busy to meet with all the clients.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "52-1.mp3",
        "training_audio": "52-1.mp3",
        "text": "Man: We mainly promote from within the company, but please do send us your resumé.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The company does not accept many job applications for higher positions from outside the company",
                        "is_correct": true
                    },
                    {
                        "text": "The company is currently recruiting new trainees.",
                        "is_correct": false
                    },
                    {
                        "text": "It is not worth sending a CV to the company.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "53-1.mp3",
        "training_audio": "53-1.mp3",
        "text": "Woman: There's been a significant fall in demand for our product since the recent TV report.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "Demand for their product remains steady.",
                        "is_correct": false
                    },
                    {
                        "text": "The TV report has boosted sales.",
                        "is_correct": false
                    },
                    {
                        "text": "Demand for their product has declined significantly.",
                        "is_correct": true
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "54-1.mp3",
        "training_audio": "54-1.mp3",
        "text": "Man: What are the product reviews like?\nWoman: We hardly ever get negative feedback.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean? \n",
                "correction": null,
                "choices": [
                    {
                        "text": "It is hard to accept negative reviews.",
                        "is_correct": false
                    },
                    {
                        "text": "The reviews are generally positive.",
                        "is_correct": true
                    },
                    {
                        "text": "They should do more to improve the product.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "55-1.mp3",
        "training_audio": "55-1.mp3",
        "text": "Woman: Can you identify any new market trends affecting the industry?\nMan: We can't ignore growing consumer demand for sustainable practices.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man say?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The company needs to take into consideration public concern for the environment.",
                        "is_correct": true
                    },
                    {
                        "text": "There is no need for the company to adopt more environmentally friendly  practices.",
                        "is_correct": false
                    },
                    {
                        "text": "The company should do more market research to find out what consumers really want.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "56-1.mp3",
        "training_audio": "56-1.mp3",
        "text": "Man: There's been a tendency for young families to leave the city and move back to the countryside.",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the speaker say?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Many young families are leaving their home countries in search of a better life.",
                        "is_correct": false
                    },
                    {
                        "text": "Couples with young children are exchanging city life for country living.",
                        "is_correct": true
                    },
                    {
                        "text": "More and more young couples are deserting rural areas and moving to the city.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "57-1.mp3",
        "training_audio": "57-1.mp3",
        "text": "Man: There's a great online offer here - it says that if you sign up to their newsletter, you can get up to 50% off on your first order.\nWoman: There must be a catch.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman imply?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "The offer is too good to be true.",
                        "is_correct": true
                    },
                    {
                        "text": "They should apply immediately to take advantage of the offer. ",
                        "is_correct": false
                    },
                    {
                        "text": "She's not interested in the newsletter.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "58-1.mp3",
        "training_audio": "58-1.mp3",
        "text": "Man: The increase in production by commercial coffee producers is having a negative impact on small farmers, who are forced to sell at a loss.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "Which statement is true?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "Small farmers are losing money on the sale of their coffee.",
                        "is_correct": true
                    },
                    {
                        "text": "There is less, and less coffee produced worldwide.",
                        "is_correct": false
                    },
                    {
                        "text": "Small farmers are getting a higher price for their coffee.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "59-1.mp3",
        "training_audio": "59-1.mp3",
        "text": "Woman: Do you know when the first bottled water was sold?\nMan: I haven't got a clue.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the man say?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "He doesn't know the answer to the question.",
                        "is_correct": true
                    },
                    {
                        "text": "He will give the woman a clue to the answer.",
                        "is_correct": false
                    },
                    {
                        "text": "He really is not interested in talking about bottled water. ",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    },
    {
        "exam_audio": "60-1.mp3",
        "training_audio": "60-1.mp3",
        "text": "Man: Can I get you anything?\nWoman: I wouldn't mind a soft drink.\n",
        "image": null,
        "part": 1,
        "questions": [
            {
                "text": "What does the woman mean?\n",
                "correction": null,
                "choices": [
                    {
                        "text": "She would like a non-alcoholic drink.",
                        "is_correct": true
                    },
                    {
                        "text": "She doesn't mind if the man has a drink.",
                        "is_correct": false
                    },
                    {
                        "text": "She doesn't want anything to drink or eat.",
                        "is_correct": false
                    }
                ],
                "lessons": []
            }
        ]
    }
]
