import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Question } from '../../app.component';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'polylearn-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class QuestionsComponent implements OnInit {
  @Input() questions: Question[];
  @Output() onRemoveQuestion = new EventEmitter<number>();

  @Output() onRemoveChoice = new EventEmitter<{questionIndex: number, choiceIndex: number}>();
  @Output() onAddChoice = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

}
